import { TipoIntangibleBalance } from '../../../../interfaces/balance/tipoIntangibleBalance';
import { TipoPatrimonioNetoBalance } from '../../../../interfaces/balance/tipoPatrimonioNetoBalance';
import { TipoResultadoFinancieroBalance } from '../../../../interfaces/balance/tipoResultadoFinancieroBalance';
import { BALANCE_DETALLE_SECTIONS } from '../../../../pages/balances/detalle/constants/sections';
import type { BalanceEstadoBalance } from './getEstadoBalance';
import type { BalanceEstadoResultado } from './getEstadoResultado';

export interface BalanceEstadoCashflowChild {
  id: string;
  name: string;
  total: number;
  section: string;
  total_anterior: number;
}

export interface BalanceEstadoCashflow {
  name: string;
  total: number;
  section?: string;
  total_anterior: number;
  items: BalanceEstadoCashflowChild[];
  color: 'green' | 'yellow' | 'orange';
}

export const getBalanceCashflow = (
  estadoBalance: BalanceEstadoBalance[],
  estadoResultado: BalanceEstadoResultado[],
  intangibles: TipoIntangibleBalance[],
  resultadosFinancieros: TipoResultadoFinancieroBalance[],
  patrimoniosNetos: TipoPatrimonioNetoBalance[]
) => {
  if (!estadoBalance.length || !estadoResultado.length) {
    return [];
  }

  const results = [] as BalanceEstadoCashflow[];

  const estadoBalanceItems = estadoBalance.map((e) => e.items).flat();

  const cashflowOperaciones = [
    'Cuentas a Cobrar',
    'Otros Créditos',
    'Bienes para Consumo',
    'Otros Activos',
    'Deudas Comerciales',
    'Deudas Fiscales',
    'Remuneraciones y Cargas Sociales',
    'Deuda Financiera CP',
    'Cuentas por Pagar',
    'Previsión Mant. Dragado VN'
  ];

  const EBITDA = estadoResultado.find((e) => e.name === 'EBITDA')!;
  const cashflowOperacionesItems = estadoBalanceItems
    .filter((e) => cashflowOperaciones.includes(e.name))
    .reduce((acc, item) => {
      const existingItem = acc.find((i) => i.name === item.name);
      if (existingItem) {
        if (existingItem.total === 0 && item.total !== 0) {
          return acc.map((i) => (i.name === item.name ? item : i));
        }
      } else {
        acc.push(item);
      }
      return acc;
    }, [] as typeof estadoBalanceItems);

  results.push({
    name: 'EBITDA',
    total: EBITDA.total,
    total_anterior: EBITDA.total_anterior,
    items: [],
    color: 'green'
  });

  results.push({
    name: 'Cashflow Operaciones',
    total: cashflowOperacionesItems.reduce((acc, c) => acc + c.total, 0),
    total_anterior: cashflowOperacionesItems.reduce((acc, c) => acc + c.total_anterior, 0),
    items: cashflowOperacionesItems,
    color: 'yellow'
  });

  const estadoBalanceActivosFijos = estadoBalanceItems.find((e) => e.name === 'Activos Fijos (BU)');
  const deprevacion = (
    estadoResultado.find((e) => e.name === 'Gastos ordinarios') || { items: [] }
  ).items.find((e) => e.name === 'Depreciación y Amortización');

  const activosFijos = {
    id: 'activosFijos',
    section: 'Activos Fijos',
    name: 'Activos Fijos (BU)',
    total: (estadoBalanceActivosFijos?.total || 0) + (deprevacion?.total || 0),
    total_anterior:
      (estadoBalanceActivosFijos?.total_anterior || 0) + (deprevacion?.total_anterior || 0)
  };

  results.push({
    name: 'Cashflow Inversiones',
    items: [activosFijos],
    color: 'yellow',
    total: activosFijos?.total || 0,
    total_anterior: activosFijos?.total_anterior || 0
  });

  const activosIntangibles = {
    id: 'activosIntangibles',
    name: 'Activos Intangibles',
    section: BALANCE_DETALLE_SECTIONS.INTANGIBLES,
    total: intangibles.reduce((acc, c) => acc + c.monto, 0),
    total_anterior: intangibles.reduce((acc, c) => acc + c.monto_anterior, 0)
  };
  const deudaFiscal = estadoBalanceItems.find((e) => e.name === 'Deudas Fiscales y Sociales LP')!;
  const otrosPasivos = estadoBalanceItems.find((e) => e.name === 'Otros Pasivos LP')!;

  const freeItems = [activosIntangibles, deudaFiscal, otrosPasivos].filter((e) => e);

  results.push({
    color: 'yellow',
    items: freeItems,
    name: 'Cashflow Free',
    total: freeItems.reduce((acc, c) => acc + (c?.total || 0), 0),
    total_anterior: freeItems.reduce((acc, c) => acc + (c?.total_anterior || 0), 0)
  });

  const deudas = estadoBalanceItems.filter((e) => e.name.includes('Deuda Financiera'));
  const resultadosFinancierosNetos = {
    id: 'resultadosFinancierosNetos',
    name: 'Resultados Financieros Netos',
    section: BALANCE_DETALLE_SECTIONS.RESULTADOS_FINANCIEROS,
    total: resultadosFinancieros.reduce((acc, c) => acc + c.monto, 0),
    total_anterior: resultadosFinancieros.reduce((acc, c) => acc + c.monto_anterior, 0)
  };

  const variacionesPN = {
    id: 'patrimoniosNetos',
    name: 'Variaciones en efectivo PN',
    section: BALANCE_DETALLE_SECTIONS.PATRIMONIO_NETO,
    total: patrimoniosNetos.reduce((acc, c) => acc + c.monto, 0),
    total_anterior: patrimoniosNetos.reduce((acc, c) => acc + c.monto_anterior, 0)
  };

  const ingresosExtraordinarios = estadoResultado.find(
    (e) => e.name === 'Ingresos extraordinarios'
  );

  const gastosExtraordinarios = estadoResultado.find((e) => e.name === 'Gastos Extraordinarios');

  const financieroItems = [
    ...deudas,
    resultadosFinancierosNetos,
    variacionesPN,
    {
      id: 'ingresosExtraordinarios',
      name: 'Ingresos Extraordinarios',
      total: ingresosExtraordinarios?.total || 0,
      section: BALANCE_DETALLE_SECTIONS.INGRESOS,
      total_anterior: ingresosExtraordinarios?.total_anterior || 0
    },
    {
      id: 'gastosExtraordinarios',
      name: 'Gastos Extraordinarios',
      total: gastosExtraordinarios?.total || 0,
      section: BALANCE_DETALLE_SECTIONS.GASTOS,
      total_anterior: gastosExtraordinarios?.total_anterior || 0
    }
  ].filter((e) => e);

  results.push({
    color: 'yellow',
    items: financieroItems,
    name: 'Cashflow Financiero',
    total: financieroItems?.reduce((acc, c) => acc + c.total, 0) || 0,
    total_anterior: financieroItems?.reduce((acc, c) => acc + c.total_anterior, 0) || 0
  });

  const inversionesTransitorias = estadoBalanceItems.find(
    (e) => e.name === 'Inversiones Transitorias'
  )!;

  results.push({
    name: inversionesTransitorias.name || 'Inversiones Transitorias',
    total: inversionesTransitorias.total || 0,
    section: BALANCE_DETALLE_SECTIONS.INVERSIONES,
    total_anterior: inversionesTransitorias.total_anterior || 0,
    color: 'green',
    items: []
  });

  results.push({
    name: 'Variaciones Efectivo',
    total: results.slice(-2).reduce((acc, item) => acc + item.total, 0),
    total_anterior: results.slice(-2).reduce((acc, item) => acc + item.total_anterior, 0),
    color: 'orange',
    items: []
  });

  const efectivo = estadoBalanceItems.find((e) => e.name === 'Cajas y Bancos')!;

  results.push({
    name: 'Variación anual',
    total: efectivo?.total - efectivo?.total_anterior,
    total_anterior: 0,
    color: 'orange',
    items: [
      {
        name: 'Efectivo al inicio',
        total: efectivo?.total_anterior,
        total_anterior: 0,
        section: 'Efectivo',
        id: ''
      },
      {
        name: 'Efectivo al cierre',
        total: efectivo?.total,
        total_anterior: 0,
        section: 'Efectivo',
        id: ''
      }
    ]
  });

  return results;
};
