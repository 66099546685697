// Service
import BalancesService, { BalanceService } from '../../../services/balance/balance.service';

// Redux
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

// Interfaces
import type {
  GastoBalance,
  IngresoBalance,
  BalanceDetalle
} from '../../../interfaces/balance/balance';
import { BalanceEstadoBalance, getEstadoBalance } from './utils/getEstadoBalance';
import { getEstadoResultado, BalanceEstadoResultado } from './utils/getEstadoResultado';

import type { TipoBienUsoBalance } from '../../../interfaces/balance/tipoBienUsoBalance';
import { TipoCuentaBceBalance } from '../../../interfaces/balance/tipoCuentaBceBalance';
import { TipoCajaBancoBalance } from '../../../interfaces/balance/tipoCajaBancoBalance';
import { TipoInversionBalance } from '../../../interfaces/balance/tipoInversionBalance';
import { TipoResultadoFinancieroBalance } from '../../../interfaces/balance/tipoResultadoFinancieroBalance';
import { RootState } from '../../store';
import { getBalanceCashflow } from './utils/getBalanceCashflow';
import TipoCuentasBceService from '../../../services/balance/parametros/tipoCuentaBce.service';
import { TipoCuentaBce } from '../../../interfaces/balance/parametros/tipoCuentaBce';
import { TipoIntangibleBalance } from '../../../interfaces/balance/tipoIntangibleBalance';
import { TipoMonedaExtranjeraBalance } from '../../../interfaces/balance/tipoMonedaExtranjeraBalance';
import { TipoPatrimonioNetoBalance } from '../../../interfaces/balance/tipoPatrimonioNetoBalance';

const initialState: {
  error: string;
  loading: boolean;
  balance: BalanceDetalle | null;
  loadingEstadosResultado: boolean;
  estadoBalance: BalanceEstadoBalance[];
  estadoResultado: BalanceEstadoResultado[];
  intangibles: TipoIntangibleBalance[];
  resultadosFinancieros: TipoResultadoFinancieroBalance[];
  patrimoniosNetos: TipoPatrimonioNetoBalance[];
} = {
  error: '',
  balance: null,
  loading: true,
  loadingEstadosResultado: true,

  estadoBalance: [],
  estadoResultado: [],

  intangibles: [],
  resultadosFinancieros: [],
  patrimoniosNetos: []
};

export const fetchBalance = createAsyncThunk<BalanceDetalle, number>(
  'balance/fetchBalanceStatus',
  async (id, { rejectWithValue }) => {
    try {
      return await BalancesService.getBalance(id);
    } catch (err) {
      // @ts-ignore
      return rejectWithValue(err?.response?.status);
    }
  }
);

export const fetchBalanceEstadoResultado = createAsyncThunk<BalanceEstadoResultado[], number>(
  'balance/fetchBalanceEstadoResultado',
  async (id, { rejectWithValue }) => {
    try {
      const promises = [
        BalancesService.getGastosBalance(id),
        BalancesService.getIngresosBalance(id),
        BalanceService.getBienesUsoBalance(id),
        BalanceService.getResultadosFinancierosBalance(id)
      ];

      const [gastos, ingresos, bienUso, resultadosFinancieros] = await Promise.all(promises);

      return getEstadoResultado(
        ingresos as { data: IngresoBalance[] },
        gastos as { data: GastoBalance[] },
        bienUso as { data: TipoBienUsoBalance[] },
        resultadosFinancieros as { data: TipoResultadoFinancieroBalance[] }
      );
    } catch (err) {
      console.log(err);
      // @ts-ignore
      return rejectWithValue(err?.response?.status);
    }
  }
);

export const fetchEstadoBalance = createAsyncThunk<
  [
    BalanceEstadoBalance[],
    TipoIntangibleBalance[],
    TipoResultadoFinancieroBalance[],
    TipoPatrimonioNetoBalance[]
  ],
  number
>('balance/fetchEstadoBalance', async (id, { rejectWithValue }) => {
  try {
    const promises = [
      BalancesService.getCuentasBceBalance(id),
      BalancesService.getCajasBancosBalance(id),
      BalanceService.getMonedasExtranjerasBalance(id),
      BalanceService.getBienesUsoBalance(id),
      BalanceService.getInversionesBalance(id),
      TipoCuentasBceService.getTiposCuentasBce(),
      BalancesService.getIntangiblesBalance(id),
      BalanceService.getResultadosFinancierosBalance(id),
      BalanceService.getPatrimoniosNetosBalance(id)
    ];

    const [
      cuentas,
      cajas,
      monedas,
      bienesUso,
      inversiones,
      cuentasBce,
      intangibles,
      resultados,
      patrimonios
    ] = await Promise.all(promises);

    return [
      getEstadoBalance(
        cuentas as { data: TipoCuentaBceBalance[] },
        cajas as { data: TipoCajaBancoBalance[] },
        monedas as { data: TipoMonedaExtranjeraBalance[] },
        bienesUso as { data: TipoBienUsoBalance[] },
        inversiones as { data: TipoInversionBalance[] },
        intangibles as { data: TipoIntangibleBalance[] },
        {
          cuentasBce: cuentasBce as TipoCuentaBce[]
        }
      ),
      (intangibles as { data: TipoIntangibleBalance[] }).data,
      (resultados as { data: TipoResultadoFinancieroBalance[] }).data,
      (patrimonios as { data: TipoPatrimonioNetoBalance[] }).data
    ];
  } catch (err) {
    console.log(err);

    // @ts-ignore
    return rejectWithValue(err?.response?.status);
  }
});

const balanceDetalleSlice = createSlice({
  name: 'balanceDetalle',
  initialState,
  reducers: {
    clearError(state) {
      state.error = '';
    },

    reset(state) {
      state.error = '';
      state.balance = null;
      state.loading = true;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBalance.fulfilled, (state, action) => {
      state.balance = action.payload;
      state.loading = false;
      state.error = '';
    });

    builder.addCase(fetchBalance.pending, (state, action) => {
      if (!state.balance || state.balance.id !== action.meta.arg) state.loading = true;
      state.error = '';
    });

    builder.addCase(fetchBalance.rejected, (state, error) => {
      state.loading = false;
      // @ts-ignore
      state.error = error.payload || '500';
    });

    builder.addCase(fetchBalanceEstadoResultado.pending, (state) => {
      state.loadingEstadosResultado = true;
      state.error = '';
    });

    builder.addCase(fetchEstadoBalance.pending, (state) => {
      state.loadingEstadosResultado = true;
      state.error = '';
    });

    builder.addCase(fetchBalanceEstadoResultado.fulfilled, (state, action) => {
      state.estadoResultado = action.payload;
      state.loadingEstadosResultado = false;
      state.error = '';
    });

    builder.addCase(fetchEstadoBalance.fulfilled, (state, action) => {
      state.estadoBalance = action.payload[0];
      state.intangibles = action.payload[1];
      state.resultadosFinancieros = action.payload[2];
      state.patrimoniosNetos = action.payload[3];
      state.loadingEstadosResultado = false;
      state.error = '';
    });
  }
});

export const selectBalanceCashflow = createSelector(
  [
    (state: RootState) => state.balanceDetalle.estadoBalance,
    (state: RootState) => state.balanceDetalle.estadoResultado,
    (state: RootState) => state.balanceDetalle.intangibles,
    (state: RootState) => state.balanceDetalle.resultadosFinancieros,
    (state: RootState) => state.balanceDetalle.patrimoniosNetos
  ],
  (estadoBalance, estadoResultado, intangibles, resultadosFinancieros, patrimoniosNetos) => {
    return getBalanceCashflow(
      estadoBalance,
      estadoResultado,
      intangibles,
      resultadosFinancieros,
      patrimoniosNetos
    );
  }
);

export const balanceDetalleActions = {
  fetchBalance,
  fetchEstadoBalance,
  fetchBalanceEstadoResultado,
  ...balanceDetalleSlice.actions
};

export default balanceDetalleSlice.reducer;
