import axios from '../../../config/axios';
import { API_URL } from '../../../config';

import type { TipoOperacion } from '../../../interfaces/lote/parametros/tiposOperaciones';

/**
 * @description Recupera desde la API el listado de tipos de operaciones que realizan los buques
 * @returns {Promise<TipoOperacion[]>}
 */
const getTiposOperaciones = async (params: { search?: string } = {}): Promise<TipoOperacion[]> => {
  return await axios
    .get<{ data: TipoOperacion[] }>(`${API_URL}/tipos-operacion`, { params })
    .then((response) => {
      return response.data.data;
    });
};

const TipoOperacionService = {
  getTiposOperaciones
};

export default TipoOperacionService;
