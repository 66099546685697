import axios from '../../../config/axios';
import { API_URL } from '../../../config';

import type { TipoPatrimonioNeto } from '../../../interfaces/balance/parametros/tipoPatrimonioNeto';

const PATH = `${API_URL}/contable`;

/**
 * @description Recupera desde la API el listado de tipos resultados financieros
 */
const getPatrimoniosNetos = async () => {
  return await axios
    .get<{ data: TipoPatrimonioNeto[] }>(`${PATH}/tipos-patrimonio-neto`)
    .then((response) => {
      return response.data.data;
    });
};

const TipoPatrimonioNetoService = {
  getPatrimoniosNetos
};

export default TipoPatrimonioNetoService;
