import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Interfaces
import type { RootState } from '../../../store';

// Service
import TipoPatrimonioNetoService from '../../../../services/balance/parametros/tipoPatrimonioNeto.service';

type Entity = Awaited<ReturnType<typeof TipoPatrimonioNetoService.getPatrimoniosNetos>>[number];

export const fetchTiposPatrimonioNetos = createAsyncThunk<Entity[]>(
  'tiposPatrimonioNeto/fetchTiposPatrimonioNetos',
  (_params, api) => {
    const { tiposPatrimonioNeto } = api.getState() as RootState;
    if (tiposPatrimonioNeto.entities.length) return Promise.resolve(tiposPatrimonioNeto.entities);
    return TipoPatrimonioNetoService.getPatrimoniosNetos();
  }
);

interface TiposPatrimoniosNetosState {
  loading: boolean;
  entities: Entity[];
}

const initialState = {
  entities: [],
  loading: true
} as TiposPatrimoniosNetosState;

const tiposPatrimonioNetoSlice = createSlice({
  name: 'tiposPatrimonioNeto',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTiposPatrimonioNetos.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchTiposPatrimonioNetos.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchTiposPatrimonioNetos.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const tiposPatrimoniosNetosActions = {
  ...tiposPatrimonioNetoSlice.actions,
  fetchTiposPatrimonioNetos
};

export default tiposPatrimonioNetoSlice.reducer;
